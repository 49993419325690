import React, { Component } from 'react';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;


class Dialpad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUrlOpen : false
        }
    }

    callConnected = (ev) => {
        if(ev && ev.detail && ev.detail.currentCall && ev.detail.currentCall.initialPayload && ev.detail.currentCall.initialPayload.verto_h_originalCallerIdNumber){
            let number = ev.detail.currentCall.initialPayload.verto_h_originalCallerIdNumber;
            try {
                let numberObject = phoneUtil.parse(number, "")
                number = phoneUtil.format(numberObject, PNF.NATIONAL).replace(/[^0-9\.]+/g, '')
                console.log(`@dev: opening https://dash.foodics.com/orders/item?customer_phone=${number}`)
                window.open(`https://console.foodics.com/orders?phone=${number}&dial_code=966`)
            } catch(ex) {
                number = number.replace(/[^0-9\.]+/g, '')
                console.log(`@dev: opening https://dash.foodics.com/orders/item?customer_phone=${number}`)
                window.open(`https://console.foodics.com/orders?phone=${number}&dial_code=966`)  
            }
        } else if(ev && ev.detail && ev.detail.customerNumber) {
            console.log("@dev: outbound", ev.detail.customerNumber)
            let number = ev.detail.customerNumber;
            try {
                let numberObject = phoneUtil.parse(number, "")
                number = phoneUtil.format(numberObject, PNF.NATIONAL).replace(/[^0-9\.]+/g, '')
                console.log(`@dev: opening https://dash.foodics.com/orders/item?customer_phone=${number}`)
                window.open(`https://console.foodics.com/orders?phone=${number}&dial_code=966`)
            } catch(ex) {
                number = number.replace(/[^0-9\.]+/g, '')
                console.log(`@dev: opening https://dash.foodics.com/orders/item?customer_phone=${number}`)
                window.open(`https://console.foodics.com/orders?phone=${number}&dial_code=966`)
            }
        } else {
            console.log("@dev: number not found", ev)
        }
    }

    callDisconnected = (ev) => {
        console.log("call disconnected: ", ev)
    }


    componentDidMount() {
        window.addEventListener('ziwo-ringing', (ev) => console.log("ziwo-ringing", ev));
        window.addEventListener('ziwo-hangup', (ev) => console.log("ziwo-hangup", ev));
        window.addEventListener('ziwo-early', (ev) => console.log("ziwo-early", ev));
        window.addEventListener('ziwo-active', (ev) => this.callConnected(ev));
        window.addEventListener('ziwo-connected', (ev) => console.log("ziwo-connected", ev));
        window.addEventListener('ziwo-disconnected', (ev) => console.log("ziwo-disconnected", ev));
        window.addEventListener('ziwo-recovering', (ev) => console.log("ziwo-recovering", ev));
        window.addEventListener('ziwo-destroy', (ev) => console.log("ziwo-destroy", ev));
        window.addEventListener('ziwo-error', (ev) => console.log("ziwo-error", ev));
    }

    render() {
        let { showSettings, contact } = this.state
        return (
            <div>
                <ziwo-dialpad autorun="true"></ziwo-dialpad>
            </div>
        );
    }
}

export default Dialpad;